import React from 'react';
import PropTypes from 'prop-types';

const YoutubeEmbed = ({ embedId }) => (
    <div style={{
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
        height: 0,
    }}>
        <iframe
            width='853'
            height='480'
            src={`https://www.youtube.com/embed/${embedId}`} //?autoplay=1
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded youtube'
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
            }}
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;