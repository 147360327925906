export default function AppBar() {
    return (
        <div
            style={{
                fontWeight: 900,
                fontSize: 20,
                padding: '18px 18px',
            }}
        >
            <a href='https://twitter.com/SmashHighlights' style={{ textDecoration: 'none', color: 'black' }}>
                @SmashHighlights
            </a>
        </div>
    );
}
