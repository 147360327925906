import React from 'react';
// { useEffect, useState } 
import { useMediaQuery } from 'react-responsive'
import AppBar from './AppBar';
import switchScreenshot1 from './PenguinParkourSwitchImage1.png';
import penguinImage1 from './PenguinImage1.png';

import youtubeIcon from './SocialMediaIcon_Youtube128.png';
import youtubeIconLarger from './SocialMediaIcon_YoutubeLarger128.png';
import twitterIcon from './SocialMediaIcon_Twitter128.png';

import YoutubeEmbed from './YoutubeEmbed';


export default function PenguinParkour() {
    const isDesktop = useMediaQuery({ query: '(min-width: 900px)' });

    if (isDesktop) {
        return (
            <div
                style={{
                    maxWidth: 1100,
                    margin: 'auto',
                }}
            >
                <AppBar />
                <img src={switchScreenshot1}
                    alt="Switch Screenshot"
                    width={100}

                />;
            </div>
        );
    }

    return (
        <div
            style={{
                // maxWidth: 600,
                margin: 'auto',
            }}
        >
            <AppBar />

            <div style={{ height: 55 }} />

            <div style={{ margin: '0px 18px', background: 'rgba(0, 0, 0, 0.1)' }}>
                <YoutubeEmbed embedId='rokGy0huYEA' />
            </div>


            <div
                style={{
                    margin: '0px 18px'
                }}>
                <Heading
                    text={'Play on the go'}
                />
                <Body
                    text={'This is some text about playing Penguin Parkour on the go on your Nintendo Switch. This is some text about playing Penguin Parkour on the go on your Nintendo Switch.'}
                />
            </div>


            <img src={switchScreenshot1}
                alt='Switch Screenshot'
                style={{
                    width: '100%',
                    height: 250,
                    objectFit: 'contain',
                    background: 'rgba(0,0,0,0.1)'
                }}
            />
            <div
                style={{
                    margin: '0px 18px'
                }}>
                <Heading
                    text={'Play on the go'}
                />
                <Body
                    text={'This is some text about playing Penguin Parkour on the go on your Nintendo Switch. This is some text about playing Penguin Parkour on the go on your Nintendo Switch.'}
                />
            </div>




            <img src={penguinImage1}
                alt='Penguin image'
                style={{
                    width: '100%',
                    height: 250,
                    objectFit: 'contain',
                    background: 'rgba(0,0,0,0.1)'
                }}
            />
            <div
                style={{
                    margin: '0px 18px'
                }}>
                <Heading
                    text={'Play on the go'}
                />
                <Body
                    text={'This is some text about playing Penguin Parkour on the go on your Nintendo Switch. This is some text about playing Penguin Parkour on the go on your Nintendo Switch.'}
                />
            </div>


            <div style={{ height: 30 }} />

            <Footer />

        </div>
    );
}


function Heading({ text }) {
    return <div
        style={{
            marginTop: 14,
            fontSize: 26,
            // letterSpacing: 0.2,
            fontWeight: 800,
            color: 'black',
        }}
    >
        {text}
    </div>;
}

function Body({ text }) {
    return <div
        style={{
            marginTop: 10,
            marginBottom: 42,
            fontSize: 15,
            lineHeight: 1.7,
            letterSpacing: 0.2,
            fontWeight: 300,
            color: 'black',
        }}
    >
        {text}
    </div>;
}


function Footer() {
    return <div
        style={{
            background: '#222',
            padding: '22px 18px',
            display: 'flex',
            flexDirection: 'column',
        }}
    >

        <div style={{
            display: 'flex',
            margin: 'auto',
            flexDirection: 'row',
            alignItems: 'center',
            // background: 'red',
            padding: '8px 0px',
        }}>
            <a href='https://www.youtube.com/smashhighlights'
                style={{ textDecoration: 'none' }}
            >
                <img src={youtubeIconLarger} style={{ width: 32 }} />
            </a>
            <div style={{ width: 30 }} />
            <a href='https://twitter.com/SmashHighlights'
                style={{ textDecoration: 'none' }}
            >
                <img src={twitterIcon} style={{ width: 28 }} />
            </a>
        </div>

        <div style={{
            color: 'white',
            // background: 'red',
            margin: 'auto',
            padding: '10px 0px',
            fontSize: 13,
            fontWeight: 700,
        }}>
            Perren Consulting Ltd
        </div>

    </div>;
}