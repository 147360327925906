import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom'; //Redirect
import './App.css';
import PenguinParkour from './PenguinParkour';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<PenguinParkour/>} />
          <Route path='/PenguinParkour' element={<PenguinParkour/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
